import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {closestCenter, DndContext, PointerSensor, useSensor} from '@dnd-kit/core';
import {arrayMove, SortableContext, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import ThSortable from "../ThSortable";

export const TableSortable = ({header, rows, loading, sort}) => {
    const [items, setItems] = useState([]);
    const [hideSortable, setHideSortable] = useState(true);

    const sensors = [useSensor(SmartPointerSensor)];

    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const orderBy = urlParams.get('order_by');
        const orderDir = urlParams.get('order_dir');
        setHideSortable(orderBy && orderDir);
    }, [location.search]);

    useEffect(() => {
        setItems([...rows]);
    }, [rows])

    const handleDragEnd = ({active, over}) => {
        const oldIndex = items.findIndex(item => item.id === active.id);
        const newIndex = items.findIndex(item => item.id === over.id);

        let newItems = arrayMove(items, oldIndex, newIndex);
        setItems(newItems);
        executeSort(newItems);
    }

    const executeSort = items => {
        let toSort = [];
        items.map((item, index) => {
            toSort.push({
                id: item.id,
                posicao: index
            });
        });
        sort(toSort);
    }

    return (
        <table className="table">
            <thead>
                <tr>
                    {!hideSortable &&
                        <th></th>
                    }
                    {header.map((item, index) => (
                        <ThSortable key={index} item={item} />
                    ))}
                </tr>
            </thead>
            <tbody>
                {!loading && rows.length === 0 &&
                    <tr>
                        <td colSpan={header.length} className="no-data">Nenhum resultado encontrado</td>
                    </tr>
                }
                {loading &&
                    <tr>
                        <td colSpan={header.length} className="no-data">Carregando...</td>
                    </tr>
                }
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext 
                        items={items.map(item => item.id)}
                    >
                        {items.map(item => (
                            <SortableItem key={item.id} item={item} hideSortable={hideSortable} />
                        ))}
                    </SortableContext>
                </DndContext>
            </tbody>
        </table>        
    );
}


function SortableItem(props) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({id: props.item.id});
    
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      listStyle: 'none'
    };

    const handleOnClick = row => {
        if(row.action){
            row.action();
            return;
        }

        if(row.to){
            window.location.href=row.to;
            return;
        }
    }
    
    return (
        // <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
        //     <td>{props.item.id}</td>
        //     <td>{props.item.text}</td>
        // </tr>

        <tr 
            ref={setNodeRef} 
            style={style} 
            {...attributes} 
            {...listeners}
            onClick={() => handleOnClick(props.item)} 
            className={props.item.to || props.item.action? 'has-link' : ''}
        >
            {!props.hideSortable &&
                <td className="td-drag-and-drop">
                    <img 
                        src={ require(`../../assets/images/svgs/drag.svg`).default } 
                        alt="drag and drop" 
                        style={{ pointerEvents: 'none', width: 15}}
                    />
                </td>
            }
            {props.item.data.map((item, index) => (
                <td key={index}>{item}</td>
            ))}
        </tr>
    );
}

export class SmartPointerSensor extends PointerSensor {
    static activators = [
        {
            eventName: "onPointerDown",
            handler: ({ nativeEvent: event }) => {
                if (
                    !event.isPrimary ||
                    event.button !== 0 ||
                    isInteractiveElement(event.target)
                ) {
                    return false;
                }

                return true;
            },
        },
    ];
}

function isInteractiveElement(element) {
    return !element.classList.contains('td-drag-and-drop');
}


export default TableSortable;
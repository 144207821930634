import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import logo from '../../assets/images/svgs/logo.svg';

import { useLocation } from "react-router-dom";
import { getUser, logout } from "../../services/auth";
import Button from "../Button";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { toast } from "react-toastify";

const items = [
    // { name: '', svg: 'logo', to: '' },
    { name: 'Projetos', svg: 'company', to: '/projects/list' },
    { name: 'Plantas', svg: 'plants', to: '/plan/list' },
    { name: 'Bairros', svg: 'place', to: '/district/list' },
    { name: 'Status', svg: 'calendar-light', to: '/status/list' },
    { name: 'Usuários', svg: 'user', to: '/user/list' },
];

export const SidebarItem = ({ item }) => {
    const [active, setActive] = useState(false);

    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        if (pathname == item.to) {
            setActive(true);
        }
        if (item.subitems) {
            item.subitems.map(subitem => {
                if (subitem.to === pathname) {
                    setActive(true);
                }
            });
        }
    }, []);

    return (
        <>
            <li className={active ? 'active' : ''}>
                {!item.subitems &&
                    <Link to={item.to}>
                        <img src={require(`../../assets/images/svgs/${item.svg}.svg`)} alt={item.name} />
                        {item.name}
                    </Link>
                }
                {item.subitems &&
                    <a onClick={() => setActive(prev => !prev)}>
                        <img src={require(`../../assets/images/svgs/${item.svg}.svg`)} alt={item.name} />
                        {item.name}
                        {item.subitems &&
                            <img src={require(`../../assets/images/svgs/arrow.svg`).default} className="arrow" alt="alterar" />
                        }
                    </a>
                }

                {item.subitems &&
                    <ul>
                        {item.subitems.map((subitem, index) => (
                            <SidebarSubitem subitem={subitem} key={index} />
                        ))}
                    </ul>
                }
            </li>
        </>
    );
}

export const SidebarSubitem = ({ subitem }) => {
    const [active, setActive] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === subitem.to) {
            setActive(true);
        }
    }, []);

    return (
        <li className={active ? 'active' : ''}>
            <Link to={subitem.to}>
                <span className="dot"></span>
                {subitem.name}
            </Link>
        </li>
    );
}


export const Sidebar = () => {
    const user = getUser();
    const navigate = useNavigate();
    const [loadingProduction, setLoadingProduction] = useState(false);

    const signOut = () => {
        logout();
        navigate(`/login`);
    }

    const updateProduction = () => {
        setLoadingProduction(true);
        api.put('/producao').then(res => {
            toast.success('Site atualizado em produção');
        }).catch(error => {
            renderError(error);
        }).then(() => setLoadingProduction(false));
    }

    return (
        <>
            <div className="box-sidebar">
                <div className="box-logo">
                    <img src={logo} alt="Logo" />
                </div>
                <aside id="sidebar">
                    <ul>
                        {items.map((item, index) => (
                            <SidebarItem item={item} key={index} />
                        ))}
                    </ul>
                    <div className="sidebar-footer-actions">
                        {!window.location.href.includes('hml') &&
                            <button 
                            className="btn-production"
                            onClick={updateProduction}
                            >
                                {loadingProduction ? 'Atualizando...' : 'Atualizar site em produção'}
                            </button>
                        }
                        <div id="user">
                            <Link to="/">{user.nome}</Link>
                            <Button type={`transparent`} svg={`logout`} action={signOut} />
                        </div>
                    </div>
                </aside>
            </div>
        </>
    );
}

export default Sidebar;
import React from 'react';
import Routes from './routes';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';
const App = () => (
  <>
    <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    theme='colored'
    pauseOnFocusLoss
    draggable
    pauseOnHover
    />
    <Routes />
  </>
);

export default App;

import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import { createOptions } from "../../helpers/selects";
import { toast } from "react-toastify";
import ModalDelete from "../../components/ModalDelete";

export const StatusList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');
    const [data, setData] = useState({ header: [], rows: [] });
    const [allStatus, setAllStatus] = useState([]);

    const [showCreateStatus, setShowCreateStatus] = useState(false);
    const [showUpdateStatus, setShowUpdateStatus] = useState(false);

    const [status, setStatus] = useState(null);
    const [statusNome, setStatusNome] = useState('');

    const [statusToDelete, setStatusToDelete] = useState(null);

    useEffect(() => {
        getAllStatus();
    }, []);

    useEffect(() => {
        if (filters) getAllStatus();
    }, [filters]);

    useEffect(() => {
        if (!showUpdateStatus) clean();
    }, [showUpdateStatus]);

    useEffect(() => {
        generateData();
    }, [allStatus]);

    const getAllStatus = () => {
        setAllStatus([]);
        setLoading(true);

        api.get(`/status${filters}`).then(res => {
            setAllStatus(res.data.data);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome', 'Última atualização'];
        const rows = [];

        allStatus.map(status => {
            rows.push({
                data: [
                    status.nome,
                    moment(status.updated_at).format('DD/MM/YYYY HH:mm')
                ],
                action: () => editStatus(status)
            })
        });

        setData({ header, rows });
    }

    const storeStatus = () => {
        setLoading(true);

        api.post(`/status`, {
            nome: statusNome,
        }).then(res => {
            toast.success('Status cadastrado com sucesso');
            setShowCreateStatus(false);
            getAllStatus();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateStatus = () => {
        setLoading(true);

        api.put(`/status/${status.id}`, {
            nome: statusNome
        }).then(res => {
            toast.success('Status atualizado com sucesso');
            setShowUpdateStatus(false);
            getAllStatus();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editStatus = status => {
        setStatus(status);
        setStatusNome(status.nome);
        setShowUpdateStatus(true);
    }

    const clean = () => {
        setStatus(null);
        setStatusNome('');
    }

    const deleteStatus = () => {
        setLoading(true);

        api.delete(`/status/${statusToDelete.id}`).then(res => {
            setStatusToDelete(null);
            setShowUpdateStatus(false);
            getAllStatus();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <Modal
                title={`Cadastrar status`}
                show={showCreateStatus}
                close={() => setShowCreateStatus(false)}
                footer={
                    <Button
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeStatus}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-12">
                            <Input type={`text`} label={`Nome`} change={setStatusNome} value={statusNome} />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                title={`Atualizar status`}
                show={showUpdateStatus}
                close={() => setShowUpdateStatus(false)}
                footer={
                    <>
                        <Button
                            type={`secondary`}
                            size={`small`}
                            text={`Excluir`}
                            svg={`delete-small`}
                            action={() => setStatusToDelete(status)}
                            loading={loading}
                        />
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={`Salvar alterações`}
                            svg={`save-white`}
                            action={updateStatus}
                            loading={loading}
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-12">
                            <Input type={`text`} label={`Nome`} change={setStatusNome} value={statusNome} />
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalDelete
                show={statusToDelete !== null}
                action={deleteStatus}
                close={() => setStatusToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="product-list">
                <PageHeader
                    left={
                        <FilterSimple
                            params={[
                                { name: 'Nome', key: 'nome', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar status`} svg="plus-white" action={() => setShowCreateStatus(true)} />
                        </>
                    }
                />

                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default StatusList;
export const createOptions = (array, label = null, value = null, nullable = false) => {
    let options = [];

    if(nullable){
        options.push({
            value: null,
            label: 'Nenhuma'
        });
    }

    array.map(item => {
        let hasItem = options.filter(o => o.value === item[value]).length === 0 ? false : true;
        if(!hasItem){
            options.push({
                label: label === null ? item : item[label],
                value: value === null ? item : item[value]
            })
        }
    });

    return options;
}
import React from "react";
import Button from "../Button";
import Modal from "../Modal";

export const ModalDelete = ({action, close, show, loading}) => {
    return (
        <Modal 
            className={`modal-close`}
            show={show}
            title={`Confirmar exclusão`}
            close={close}
            footer={
                <>
                    <Button type={`primary`} size={`small`} svg={`delete-small-white`} text={`Confirmar`} action={action} loading={loading}/>
                    {!loading &&
                        <Button type={`secondary`} size={`small`} text={`Cancelar`} action={close}/>
                    }
                </>
            }
        >
            <div className="section">
                <img src={ require(`../../assets/images/gifs/delete.gif`)} />
                Tem certeza que deseja realizar esta exclusão?
            </div>
        </Modal>
    );
}

export default ModalDelete;
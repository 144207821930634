import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import ModalDelete from "../../components/ModalDelete";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import { createOptions } from "../../helpers/selects";
import { toast } from "react-toastify";

export const ProjectList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');
    const [data, setData] = useState({header: [], rows: []});
    const [projects, setProjects] = useState([]);
    const [bairros, setBairros] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [showCreateProject, setShowCreateProject] = useState(false);
    const [showUpdateProject, setShowUpdateProject] = useState(false);

    const [project, setProject] = useState(null);
    const [projectNome, setProjectNome] = useState('');
    const [projectBairro, setProjectBairro] = useState(0);
    const [projectArquitetura, setProjectArquitetura] = useState('');
    const [projectStatus, setProjectStatus] = useState(0);

    const [projectToDelete, setProjectToDelete] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        getBairros();
        getStatuses();
    }, []);

    useEffect(() => {
        getProjects();
    }, [location.search]);

    useEffect(() => {
        if(filters) getProjects();
    }, [filters]);

    useEffect(() => {
        if(!showCreateProject) clean();
    }, [showCreateProject]);

    useEffect(() => {
        if(!showUpdateProject) clean();
    }, [showUpdateProject]);

    useEffect(() => {
        generateData();
    }, [projects]);

    const getBairros = () => {
        api.get(`/bairros`).then(res => {
            setBairros(res.data.data);
        });
    }

    const getStatuses = () => {
        api.get(`/status`).then(res => {
            setStatuses(res.data.data);
        });
    }

    const getProjects = () => {
        setProjects([]);
        setLoading(true);
        
        const urlParams = new URLSearchParams(location.search);
        const orderBy = urlParams.get('order_by');
        const orderDir = urlParams.get('order_dir');
        
        let queryString = filters;
        if(orderBy && orderDir){
            queryString += `&order_by=${orderBy}&order_dir=${orderDir}`;
        }

        queryString = queryString.replace('?', '');

        api.get(`/projetos?${queryString}`).then(res => {
            setProjects(res.data.data);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = [
            {label: 'Nome', value: 'nome'}, 
            {label: 'Bairro', value: 'bairro'}, 
            {label: 'Arquitetura', value: 'arquitetura'}, 
            {label: 'Status', value: 'status'}, 
            {label: 'Última atualização', value: 'updated_at'}
        ];
        const rows = [];

        projects.map(project => {
            rows.push({
                data: [
                    project.nome,
                    project.bairro,
                    project.arquitetura,
                    project.status,
                    moment(project.updated_at).format('DD/MM/YYYY HH:mm')
                ],
                action: () => editProject(project)
            })
        });

        setData({header, rows});
    }

    const storeProject = () => {
        setLoading(true);

        api.post(`/projetos`, {
            nome: projectNome,
            bairro_id: projectBairro,
            status_id: projectStatus,
            arquitetura: projectArquitetura
        }).then(res => {
            toast.success('Projeto cadastrado com sucesso');
            setShowCreateProject(false);
            getProjects();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateProject = () => {
        setLoading(true);

        api.put(`/projetos/${project.id}`, {
            nome: projectNome,
            bairro_id: projectBairro,
            status_id: projectStatus,
            arquitetura: projectArquitetura
        }).then(res => {
            toast.success('Projeto atualizado com sucesso');
            setShowUpdateProject(false);
            getProjects();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editProject = project => {
        setProject(project);
        setProjectNome(project.nome);
        setProjectBairro(project.bairro_id);
        setProjectStatus(project.status_id);
        setProjectArquitetura(project.arquitetura);
        setShowUpdateProject(true);
    }

    const clean = () => {
        setProject(null);
        setProjectNome('');
        setProjectBairro(null);
        setProjectStatus(null);
        setProjectArquitetura('');
    }

    const deleteProject = () => {
        setLoading(true);

        api.delete(`/projetos/${projectToDelete.id}`).then(res => {
            setProjectToDelete(null);
            setShowUpdateProject(false);
            getProjects();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            {showCreateProject &&
                <Modal
                    title={`Cadastrar projeto`}
                    show={true}
                    close={() => setShowCreateProject(false)}
                    footer={
                        <Button 
                            type={`primary`}
                            size={`small`}
                            text={`Cadastrar`}
                            svg={`save-white`}
                            action={storeProject}
                            loading={loading}
                        />
                    }
                >
                    <div className="section">
                        <div className="row">
                            <div className="col-6">
                                <Input type={`text`} label={`Nome`} change={setProjectNome} value={projectNome} />
                            </div>
                            <div className="col-6">
                                <InputSelect 
                                    label={`Bairro`}
                                    options={createOptions(bairros, 'nome', 'id')}
                                    value={projectBairro}
                                    change={setProjectBairro}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Input type={`text`} label={`Arquitetura`} change={setProjectArquitetura} value={projectArquitetura} />
                            </div>
                            <div className="col-6">
                                <InputSelect 
                                    label={`Status`}
                                    options={createOptions(statuses, 'nome', 'id')}
                                    value={projectStatus}
                                    change={setProjectStatus}
                                />
                            </div>
                        </div>                    
                    </div>
                </Modal>
            }
            {showUpdateProject &&
                <Modal
                    title={`Atualizar projeto`}
                    show={true}
                    close={() => setShowUpdateProject(false)}
                    footer={
                        <>
                            <Button
                                type={`secondary`}
                                size={`small`}
                                text={`Excluir`}
                                svg={`delete-small`}
                                action={() => setProjectToDelete(project)}
                                loading={loading} 
                            />
                            <Button 
                                type={`primary`}
                                size={`small`}
                                text={`Salvar alterações`}
                                svg={`save-white`}
                                action={updateProject}
                                loading={loading}
                            />
                        </>
                    }
                >
                    <div className="section">
                        <div className="row">
                            <div className="col-6">
                                <Input type={`text`} label={`Nome`} change={setProjectNome} value={projectNome} />
                            </div>
                            <div className="col-6">
                                <InputSelect 
                                    label={`Bairro`}
                                    options={createOptions(bairros, 'nome', 'id')}
                                    value={projectBairro}
                                    change={setProjectBairro}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Input type={`text`} label={`Arquitetura`} change={setProjectArquitetura} value={projectArquitetura} />
                            </div>
                            <div className="col-6">
                                <InputSelect 
                                    label={`Status`}
                                    options={createOptions(statuses, 'nome', 'id')}
                                    value={projectStatus}
                                    change={setProjectStatus}
                                />
                            </div>
                        </div>                    
                    </div>
                </Modal>
            }
            <ModalDelete
                show={projectToDelete !== null}
                action={deleteProject}
                close={() => setProjectToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="product-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome', key: 'nome', type: 'text' },
                                {name: 'Arquitetura', key: 'arquitetura', type: 'text' },
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar projeto`} svg="plus-white" action={() => setShowCreateProject(true)}  />
                        </>
                    }
                />
                
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default ProjectList;
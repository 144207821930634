import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import { createOptions } from "../../helpers/selects";
import { toast } from "react-toastify";
import ModalDelete from "../../components/ModalDelete";

export const DistrictList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');
    const [data, setData] = useState({header: [], rows: []});
    const [bairros, setBairros] = useState([]);

    const [showCreateBairro, setShowCreateBairro] = useState(false);
    const [showUpdateBairro, setShowUpdateBairro] = useState(false);

    const [bairro, setBairro] = useState(null);
    const [bairroNome, setBairroNome] = useState('');

    const [bairroToDelete, setBairroToDelete] = useState(null);

    useEffect(() => {
        getBairros();
    }, []);

    useEffect(() => {
        if(filters) getBairros();
    }, [filters]);

    useEffect(() => {
        if(!showUpdateBairro) clean();
    }, [showUpdateBairro]);

    useEffect(() => {
        generateData();
    }, [bairros]);

    const getBairros = () => {
        setBairros([]);
        setLoading(true);

        api.get(`/bairros${filters}`).then(res => {
            setBairros(res.data.data);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome', 'Última atualização'];
        const rows = [];

        bairros.map(bairro => {
            rows.push({
                data: [
                    bairro.nome,
                    moment(bairro.updated_at).format('DD/MM/YYYY HH:mm')
                ],
                action: () => editBairro(bairro)
            })
        });

        setData({header, rows});
    }

    const storeBairro = () => {
        setLoading(true);

        api.post(`/bairros`, {
            nome: bairroNome,
        }).then(res => {
            toast.success('Bairro cadastrado com sucesso');
            setShowCreateBairro(false);
            getBairros();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateBairro = () => {
        setLoading(true);

        api.put(`/bairros/${bairro.id}`, {
            nome: bairroNome
        }).then(res => {
            toast.success('Bairro atualizado com sucesso');
            setShowUpdateBairro(false);
            getBairros();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editBairro = bairro => {
        setBairro(bairro);
        setBairroNome(bairro.nome);
        setShowUpdateBairro(true);
    }

    const clean = () => {
        setBairro(null);
        setBairroNome('');
    }

    const deleteBairro = () => {
        setLoading(true);

        api.delete(`/bairros/${bairroToDelete.id}`).then(res => {
            setBairroToDelete(null);
            setShowUpdateBairro(false);
            getBairros();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <Modal
                title={`Cadastrar bairro`}
                show={showCreateBairro}
                close={() => setShowCreateBairro(false)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeBairro}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-12">
                            <Input type={`text`} label={`Nome`} change={setBairroNome} value={bairroNome} />
                        </div>
                    </div>                  
                </div>
            </Modal>
            <Modal
                title={`Atualizar bairro`}
                show={showUpdateBairro}
                close={() => setShowUpdateBairro(false)}
                footer={
                    <>
                        <Button
                            type={`secondary`}
                            size={`small`}
                            text={`Excluir`}
                            svg={`delete-small`}
                            action={() => setBairroToDelete(bairro)}
                            loading={loading} 
                        />
                        <Button 
                            type={`primary`}
                            size={`small`}
                            text={`Salvar alterações`}
                            svg={`save-white`}
                            action={updateBairro}
                            loading={loading}
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-12">
                            <Input type={`text`} label={`Nome`} change={setBairroNome} value={bairroNome} />
                        </div>
                    </div>               
                </div>
            </Modal>
            <ModalDelete
                show={bairroToDelete !== null}
                action={deleteBairro}
                close={() => setBairroToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="product-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome', key: 'nome', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar bairro`} svg="plus-white" action={() => setShowCreateBairro(true)}  />
                        </>
                    }
                />
                
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default DistrictList;
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes as ReactRouterRoutes, useLocation } from "react-router-dom";

import isAuthenticated from '../services/auth';

import Login from '../pages/Login';
import PasswordRecovery from '../pages/PasswordRecovery';

import ProjectList from '../pages/Project/list';
import DistrictList from '../pages/District/list';
import StatusList from '../pages/Status/list';
import PlanList from '../pages/Plan/list';
import UserList from '../pages/User/list';

const Routes = () => {
    const RequireAuth = ({ children }) => {
        let location = useLocation();

        if (!isAuthenticated()) {
            return <Navigate to="/login" state={{ from: location }} />;
        }

        return children;
    };


    return (
        <BrowserRouter>
            <ReactRouterRoutes>
                <Route exact path="/" element={isAuthenticated ? <Navigate to="/projects/list" /> : <Navigate to="/login" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/password-recovery" element={<PasswordRecovery />} />

                <Route path="/projects/list" element={<RequireAuth><ProjectList /></RequireAuth>} />
                <Route path="/district/list" element={<RequireAuth><DistrictList /></RequireAuth>} />
                <Route path="/status/list" element={<RequireAuth><StatusList /></RequireAuth>} />
                <Route path="/plan/list" element={<RequireAuth><PlanList /></RequireAuth>} />
                <Route path="/user/list" element={<RequireAuth><UserList /></RequireAuth>} />

            </ReactRouterRoutes>
        </BrowserRouter>
    );
}

export default Routes;
import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import InputSelect from "../../components/InputSelect";
import Input from "../../components/Input";
import { createOptions } from "../../helpers/selects";
import { toast } from "react-toastify";
import ModalDelete from "../../components/ModalDelete";

const changePasswordOptions = [
    {label: 'Sim', value: true},
    {label: 'Não', value: false}
];

export const UsertList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');
    const [data, setData] = useState({header: [], rows: []});
    const [users, setUsers] = useState([]);

    const [showCreateUser, setShowCreateUser] = useState(false);
    const [showUpdateUser, setShowUpdateUser] = useState(false);

    const [user, setUser] = useState(null);
    const [userNome, setUserNome] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userSenha, setUserSenha] = useState('');
    const [userConfirmacaoSenha, setUserConfirmacaoSenha] = useState('');

    const [changePassword, setChangePassword] = useState(false);

    const [userToDelete, setUserToDelete] = useState(null);

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if(filters) getUsers();
    }, [filters]);

    useEffect(() => {
        if(!showUpdateUser) clean();
    }, [showUpdateUser]);

    useEffect(() => {
        generateData();
    }, [users]);

    const getUsers = () => {
        setUsers([]);
        setLoading(true);

        api.get(`/users${filters}`).then(res => {
            setUsers(res.data.data);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const generateData = () => {
        const header = ['Nome', 'E-mail', 'Última atualização',  'Cadastrado em'];
        const rows = [];

        users.map(user => {
            rows.push({
                data: [
                    user.nome,
                    user.email,
                    moment(user.updated_at).format('DD/MM/YYYY HH:mm'),
                    moment(user.created_at).format('DD/MM/YYYY HH:mm')
                ],
                action: () => editUser(user)
            })
        });

        setData({header, rows});
    }

    const storeUser = () => {
        setLoading(true);

        api.post(`/users`, {
            nome: userNome,
            email: userEmail,
            senha: userSenha,
            confirmacao_senha: userConfirmacaoSenha,
        }).then(res => {
            toast.success('Usuário cadastrado com sucesso');
            setShowCreateUser(false);
            getUsers();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateUser = () => {
        setLoading(true);

        let data = {
            nome: userNome,
            email: userEmail
        };

        if(changePassword){
            data.senha = userSenha;
            data.confirmacao_senha = userConfirmacaoSenha;
        }

        api.put(`/users/${user.id}`, data).then(res => {
            toast.success('Usuário atualizado com sucesso');
            setShowUpdateUser(false);
            getUsers();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const editUser = user => {
        setUser(user);
        setUserNome(user.nome);
        setUserEmail(user.email);
        setShowUpdateUser(true);
    }

    const clean = () => {
        setUser(null);
        setUserNome('');
        setUserEmail('');
        setUserSenha('');
        setUserConfirmacaoSenha('');
        setChangePassword(false);
    }

    const deleteUser = () => {
        setLoading(true);

        api.delete(`/users/${userToDelete.id}`).then(res => {
            setUserToDelete(null);
            setShowUpdateUser(false);
            getUsers();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            <Modal
                title={`Cadastrar usuário`}
                show={showCreateUser}
                close={() => setShowCreateUser(false)}
                footer={
                    <Button 
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeUser}
                        loading={loading}
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-12">
                            <Input type={`text`} label={`Nome`} change={setUserNome} value={userNome} />
                        </div>
                    </div>  
                    <div className="row">
                        <div className="col-12">
                            <Input type={`text`} label={`E-mail`} change={setUserEmail} value={userEmail} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Input type={`password`} label={`Senha`} change={setUserSenha} value={userSenha} />
                        </div>
                    </div>             
                    <div className="row">
                        <div className="col-12">
                            <Input type={`password`} label={`Confirmação de senha`} change={setUserConfirmacaoSenha} value={userConfirmacaoSenha} />
                        </div>
                    </div>   
                </div>
            </Modal>
            <Modal
                title={`Atualizar usuário`}
                show={showUpdateUser}
                close={() => setShowUpdateUser(false)}
                footer={
                    <>
                        <Button
                            type={`secondary`}
                            size={`small`}
                            text={`Excluir`}
                            svg={`delete-small`}
                            action={() => setUserToDelete(user)}
                            loading={loading} 
                        />
                        <Button 
                            type={`primary`}
                            size={`small`}
                            text={`Salvar alterações`}
                            svg={`save-white`}
                            action={updateUser}
                            loading={loading}
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-12">
                            <Input type={`text`} label={`Nome`} change={setUserNome} value={userNome} />
                        </div>
                    </div>  
                    <div className="row">
                        <div className="col-12">
                            <Input type={`text`} label={`E-mail`} change={setUserEmail} value={userEmail} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <InputSelect label={`Mudar senha`} options={changePasswordOptions} change={setChangePassword} value={changePassword} />
                        </div>
                    </div>
                    {changePassword &&
                    <>
                        <div className="row">
                            <div className="col-12">
                                <Input type={`password`} label={`Senha`} change={setUserSenha} value={userSenha} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Input type={`password`} label={`Confirmação de senha`} change={setUserConfirmacaoSenha} value={userConfirmacaoSenha} />
                            </div>
                        </div> 
                    </>
                    }           
                </div>
            </Modal>
            <ModalDelete
                show={userToDelete !== null}
                action={deleteUser}
                close={() => setUserToDelete(null)}
                loading={loading}
            />
            <PanelTemplate id="product-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome', key: 'nome', type: 'text' },
                                {name: 'E-mail', key: 'email', type: 'text' }
                            ]}
                            filter={setFilters}
                        />
                    }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar usuário`} svg="plus-white" action={() => setShowCreateUser(true)}  />
                        </>
                    }
                />
                
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default UsertList;
import React, { useState } from "react";

import AuthTemplate from "../../templates/Auth";

import Input from "../../components/Input";
import Button from "../../components/Button";

export const PasswordRecovery = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    return (
        <AuthTemplate>
            <Input type={`email`} label={`E-mail`} change={setEmail} value={email} placeholder={`Informe seu e-mail`} />
            <Button type={`primary`} text={`Recuperar senha`} full={true} svg={`arrow-right-white`} loading={loading} />
            <Button type={`link`} text={`Voltar para o login`} full={true} loading={loading} />
        </AuthTemplate>
    );
}

export default PasswordRecovery;
export const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export const getBase64Promise = file => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const cropFileName = str => {
    if(typeof str !== 'string') return str;
    if(str.length <= 15) return str;
    return `...${str.slice(-15)}`;
}

export const splitArray = (arr, n, current) => {
    let total = arr.length;
    let limit = Math.ceil(total / n);
    let count = 0;
    let position = 0;
    let res = [];

    arr.map(obj => {
        if(!res[position]) res[position] = [];
        res[position].push(obj);
        count++;
        if(count >= limit){
            count = 0;
            position++;
        }
    });

    if(res[current]){
        return res[current];
    } else {
        return [];
    }
}

export const convertMoenyToInt = str => {
    let v = str.replace(/\D/g,'');
    v = parseInt(v);
    v = isNaN(v) ? 0 : v;
    return v;
}

export const convertIntToMoney = int => {
    let value = int / 100;
    return value.toLocaleString('pt-br', { minimumFractionDigits: 2 });
}
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ThSortable = ({ item }) => {
    const [params, setParams] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
  
    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const orderBy = urlParams.get('order_by');
      const orderDir = urlParams.get('order_dir');
      const isCurrent = orderBy === item.value;
      setParams({ orderBy, orderDir, isCurrent });
    }, [location.search, item.value]);
  
    const handleOrderChange = () => {
      const newOrderBy = item.value;
      const newOrderDir = params.orderDir === 'desc' || params.orderBy !== newOrderBy ? 'asc' : 'desc';
  
      const urlParams = new URLSearchParams(location.search);

      if(params.orderBy === item.value && params.orderDir === 'desc'){
        urlParams.delete('order_by');
        urlParams.delete('order_dir');
      } else {
        urlParams.set('order_by', newOrderBy);
        urlParams.set('order_dir', newOrderDir);
      }

  
      navigate(`?${urlParams.toString()}`);
    };

    const src = {
        asc: require(`../../assets/images/svgs/sort_asc.svg`).default,
        desc: require(`../../assets/images/svgs/sort_desc.svg`).default
    }

    const image = <img 
        src={params.isCurrent ? src[params.orderDir] : src.asc} 
        alt="text" 
        className={`sort-icon ${params.isCurrent ? 'active' : 'inactive'}`}
    />;
  
    if (typeof item === 'object' && item !== null) {
      return (
        <th onClick={handleOrderChange} className="th-sortable">
          {item.label}
          {params.isCurrent && params.orderDir === 'asc' && image}
          {params.isCurrent && params.orderDir === 'desc' && image}
          {!params.isCurrent && image}
        </th>
      );
    }
  
    return <th>{item}</th>;
};

export default ThSortable;